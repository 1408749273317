<template lang="pug">
.container
  .flex
    NavLeft.fixed
    .page
      NavTop
      router-view 
</template>
<script>
import NavTop from "@/components/dashboard/nav.top";
import NavLeft from "@/components/dashboard/nav.left";
import { useStore } from "vuex";
export default {
  name: "dashboard.viewer",
  components: {
    NavTop,
    NavLeft,
  },
  setup() {
    const store = useStore();
    store.dispatch("userStore/getMe");
    store.dispatch("userMenusStore/getMeMenus");
  },
};
</script>
<style lang="scss" scoped>
.container {
  max-width: 1900px;
  min-height: 100vh;
  padding: 0;
}
.circle-bg {
  width: 750px;
  height: 750px;
  z-index: -1;
  @apply bg-green-50 rounded-full absolute -top-52 -left-72;
}

.page {
  width: calc(100% - 18rem);
  margin-left: 18rem;
  @apply flex flex-col;
}
</style>