<template lang="pug">
.nav-top
  .group-options
    .alert-box.flex-shrink-0
      svgIcon.iconAlert(:path="mdiBellOutline", type="mdi", :size="24")
    .account-box
      .account-image
      .account-name JM. Richarte
      svgIcon.iconChevron(:path="mdiChevronDown", type="mdi", :size="24")
</template>
<script>
import { mdiBellOutline, mdiChevronDown } from "@mdi/js";
export default {
  components: {},
  data: () => ({
    mdiBellOutline,
    mdiChevronDown,
  }),
};
</script>
<style lang="scss" scoped>
.nav-top {
  @apply h-20 bg-white border-b-2 border-gray-100 flex justify-end w-full;

  .group-options {
    @apply flex;
    .alert-box,
    .account-box {
      @apply h-full border-l-2 border-gray-100 flex items-center p-4;
    }

    .alert-box {
      .iconAlert {
        @apply text-gray-300;
      }
    }

    .account-box {
      @apply flex;

      .account-image {
        @apply w-12 h-12 rounded-full border-2 border-green-500;
      }
      .account-name {
        @apply pl-4 pr-2 font-medium text-gray-400;
      }
      .iconChevron {
        @apply text-gray-400;
      }
    }
  }
}
</style>